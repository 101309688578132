import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

let backendUrl = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_HOST_URL : "http://localhost:5001";
console.log(`%c Connected to server: ${backendUrl}`, 'color: green');

ReactDOM.render(
    <App/>
    , document.getElementById("root")
);
import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
    container: {
        maxWidth: '1200px',
    },
    title: {
        fontWeight: '700',
        marginBottom: '10px',
        marginTop: '20px'
    },
    cardTitle: {
        fontWeight: '700',
        fontSize: '22px',
    },
    learnMore: {
        fontWeight: '700',
    }
}))
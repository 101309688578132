import React from 'react';
import { Container, Typography, Grid, Card, CardMedia } from '@material-ui/core'

import useStyles from './styles';

import teamVeion from "../../images/teamVeion.jpg";
const CareersIntroduction = () => {
    const classes = useStyles();
    
    return(
        <div className={classes.mainContainer}>
            <Container>
                <Grid container justify="space-around">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" className={classes.title}>Life at Veion is pretty awesome. </Typography>
                        <Typography className={classes.desc}>We believe the most innovative and creative work stems from environments where different perspectives come together. We actively seek out points of view across the company and skill sets.</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.imageContainer}>
                        <Card className={classes.cardHolder}>
                            <CardMedia
                                component="img"
                                alt="Veion"
                                height="550"
                                image={teamVeion}
                                title="Veion"
                                />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default CareersIntroduction;
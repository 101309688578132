import React from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import {  Instagram, Twitter, LinkedIn } from '@material-ui/icons';

import useStyles from './styles';

const SocialMedia = () => {
    const classes = useStyles();

    return(
        <Grid container>
          
            <Tooltip title="Instagram">
                <IconButton aria-label="Instagram" className={classes.socialMediaButton} component="a" href="https://www.instagram.com/veion.team/" target="_blank">
                    <Instagram/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Twitter">
                <IconButton aria-label="Twitter" className={classes.socialMediaButton} component="a" href="https://twitter.com/VeionT" target="_blank">
                    <Twitter/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Linked In">
                <IconButton aria-label="Linked In" className={classes.socialMediaButton} component="a" href="https://www.linkedin.com/company/veion/" target="_blank">
                    <LinkedIn/>
                </IconButton>
            </Tooltip>
        </Grid>
    );

}

export default SocialMedia;